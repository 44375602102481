import NotoSansEot from './notoSans/NotoSans-Condensed.eot';
import NotoSansOtf from './notoSans/NotoSans-Condensed.otf';
import NotoSansWoff from './notoSans/NotoSans-Condensed.woff';
import NotoSansWoff2 from './notoSans/NotoSans-Condensed.woff2';
import NotoSansTtf from './notoSans/NotoSans-Condensed.ttf';

const fontDefinitions = `
  @font-face {
    font-family: 'Noto Sans';
    src: url('${NotoSansEot}'); /* IE9 Compat Modes */
    src: url('${NotoSansEot}?#iefix') format("embedded-opentype"), /* IE6-IE8 */
      url('${NotoSansOtf}') format("opentype"), /* Open Type Font */
      url('${NotoSansTtf}') format("truetype"), /* Safari, Android, iOS */
      url('${NotoSansWoff}') format("woff"), /* Modern Browsers */
      url('${NotoSansWoff2}') format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
  }
`;

export default fontDefinitions;
