import { graphql, useStaticQuery } from 'gatsby';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import _sortBy from 'lodash/sortBy';
import localeFilter, { localeFilterList } from '@lce/intl-util/src/localeFilter';
import franchiseeFilter from '@lce/intl-util/src/FranchiseeFilter';
import { INavigationLinkProps, INavButtonProps } from '@lce/intl-ui/src/Patterns/Navigation';

import { useUpdateFranchiseeId } from '../../api/FranchiseeId/hooks/useUpdateFranchiseeId';
import useLeavingToOrderingDialog from '../LeavingToOrderingDialog';
import useFranchisees from '../FranchiseeId/hooks/useFranchisees';
import { useSelectedFranchiseeId } from '../FranchiseeId/hooks';

export interface IDatoLayoutProps {
  footer: INodeFooterProps;
  navigation: INodeNavigationProps;
  footerNavigation: INodeNavigationProps;
  socialMediaLink: IDatoSocialMediaLinks;
}

interface INodeFooterProps {
  nodes: IDatoFooterProps[];
}

interface IDatoFooterProps {
  locale: string;
  copyrightStartingYear: string;
  legalText: string;
  socialMediaText: string;
  subLegalTextNode: { childMdx: { body: any } };
}

interface INodeNavigationProps {
  nodes: IDatoNavigationProps[];
}

interface IDatoNavigationProps {
  locale: string;
  postNavButton?: INavButtonProps;
  navigationLinks: IDatoNavigationLinkProps[];
  switchLocale?: ISwitchLocale;
}

interface IDatoNavigationLinkProps {
  link: INavigationLinkProps;
}

interface ISwitchLocale {
  text: string;
  urlComponent: string;
}
interface IDatoSocialMediaLinks {
  nodes: ISocialMediaLink[];
}

interface ISocialMediaLink {
  url: string;
  position: string;
  locale: string;
}

// eslint-disable-next-line max-lines-per-function
const useLayout = (locale: string) => {
  const data: IDatoLayoutProps = useStaticQuery(graphql`
    query LayoutMsQuery {
      navigation: allDatoCmsNavigation(filter: { navigationSlug: { eq: "main-nav" } }) {
        nodes {
          locale
          navigationSlug
          franchisee {
            name
            originalId
          }
          postNavButton {
            background
            urlComponent
            text
            icon {
              url
            }
          }
          switchLocale {
            urlComponent
            text
          }
          navigationLinks {
            link {
              name
              url
              id
            }
          }
        }
      }
      footer: allDatoCmsFooter {
        nodes {
          locale
          legalText
          subLegalTextNode {
            childMdx {
              body
            }
          }
          copyrightStartingYear
          socialMediaText
        }
      }
      footerNavigation: allDatoCmsNavigation(filter: { navigationSlug: { eq: "footer-nav" } }) {
        nodes {
          locale
          navigationSlug
          postNavButton {
            background
            urlComponent
            text
            icon {
              url
            }
          }
          preNavButton {
            background
            icon {
              url
            }
            text
            urlComponent
          }
          navigationLinks {
            link {
              name
              url
              id
            }
          }
        }
      }
      socialMediaLink: allDatoCmsSocialMediaLink {
        nodes {
          locale
          url
          position
        }
      }
    }
  `);

  const { franchiseeId: SelectedFranchiseeId } = useSelectedFranchiseeId(locale);

  const leavingToOrderingDialog = useLeavingToOrderingDialog(locale);

  const filteredNavigationLinks = franchiseeFilter<IDatoNavigationProps>(data.navigation.nodes, SelectedFranchiseeId, locale);

  const navigation = filteredNavigationLinks
    ? _map(filteredNavigationLinks.navigationLinks, ({ link: { name, url, id } }) => ({
      name,
      url,
      id,
    }))
    : [];
  const filteredFooter = localeFilter(data.footer.nodes, locale);
  const filteredFooterNavigation = localeFilter(data.footerNavigation.nodes, locale);

  const footerNavigation = filteredFooterNavigation
    ? _map(filteredFooterNavigation.navigationLinks, ({ link: { name, url, id } }) => ({
      name,
      url,
      id,
    }))
    : [];
  
  const localeSocialLinks = localeFilterList(data.socialMediaLink.nodes, locale);
  
  const sortedSocialLinks = _sortBy(localeSocialLinks, (link) => link.position);

  const socialMediaLinks: string[] = [];
  
  _forEach(sortedSocialLinks, (link) => socialMediaLinks.push(link.url));

  const postNavButton = filteredNavigationLinks?.postNavButton?.urlComponent === 'LeavingToOrderingDialog'
    ? { ...filteredNavigationLinks.postNavButton, dialog: leavingToOrderingDialog }
    : filteredNavigationLinks?.postNavButton;
  
  const switchLocale = filteredNavigationLinks?.switchLocale?.urlComponent === 'SwitchLocale'
    && { ...filteredNavigationLinks.switchLocale };
  
  const { franchiseeId } = useSelectedFranchiseeId(locale);
  const { franchisees } = useFranchisees(locale);

  const [ , unsetFranchiseeId ] = useUpdateFranchiseeId();

  const handleOnClick = () => {
    unsetFranchiseeId();
    window.location.assign('/');
  };
  
  return {
    footer: {
      ...filteredFooter,
      subLegalText: filteredFooter && filteredFooter.subLegalTextNode && filteredFooter.subLegalTextNode.childMdx.body,
    },
    footerNavigation: {
      ...filteredFooterNavigation,
      navigationLinks: footerNavigation,
    },
    navigation: {
      preNavButton: {
        selectedFranchiseeId: franchiseeId,
        franchisees,
        onClick: () => handleOnClick(),
      },
      postNavButton,
      switchLocale,
      navigationLinks: navigation,
    },
    socialMediaLinks,
  };
};

export default useLayout;
