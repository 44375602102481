import { graphql, useStaticQuery } from 'gatsby';

import franchiseeFilter from '@lce/intl-util/src/FranchiseeFilter';
import { useSelectedFranchiseeId } from '../FranchiseeId/hooks/useSelectedFranchiseeId';

export interface ILeavingToOrderingDialogProps {
  leavingToOrderingDialog: INodeLeavingToOrderingDialogProps;
}

interface INodeLeavingToOrderingDialogProps {
  nodes: IDatoNavigationProps[];
}

interface IDatoNavigationProps {
  locale: string;
  text: string;
  legalText: string;
  image: {
    url: string;
  };
  orderingLinks: IOrderingLinksProp[];
  selectionText: string;
}

interface IOrderingLinksProp {
  id: string;
  linkTitle: string;
  link: string;
  logo: {
    alt?: string;
    url: string;
  };
}

// eslint-disable-next-line max-lines-per-function
const useLeavingToOrderingDialog = (locale: string) => {
  const data: ILeavingToOrderingDialogProps = useStaticQuery(graphql`
    query LeavingToOrderingDialogMsQuery {
      leavingToOrderingDialog: allDatoCmsLeavingToOrderingDialog {
        nodes {
          locale
          text
          legalText
          franchisee {
            name
            originalId
          }
          image {
            url
          }
          orderingLinks {
            id
            linkTitle
            link
            logo {
              alt
              url
            }
          }
          selectionText
        }
      }
    }
  `);
  const { franchiseeId: SelectedFranchiseeId } = useSelectedFranchiseeId(locale);


  const leavingToOrderingDialog = franchiseeFilter<IDatoNavigationProps>(data.leavingToOrderingDialog.nodes, SelectedFranchiseeId, locale);

  return {
    ...leavingToOrderingDialog,
  };
};
export default useLeavingToOrderingDialog;
