import { graphql, useStaticQuery } from 'gatsby';
import { filter as _filter } from 'lodash';
import { ILayoutProps } from '@lce/gatsby-theme-multi-site/src/ui/Layout/Layout';

interface IPageSeoProps {
  allDatoCmsSite: {
    nodes: IGlobalSeoAttributes[];
  };
  allDatoCmsPageSeo: {
    nodes: IPageSeoContent[];
  };
}

interface IPageSeoContent {
  seoContent: {
    description: string;
    title: string;
  };
  slug: string;
}

interface IGlobalSeoAttributes {
  globalSeo: {
    fallbackSeo: {
      title?: string;
      description?: string;
    };
  };
}

// eslint-disable-next-line max-lines-per-function
// eslint-disable-next-line complexity
const useSeoQuery = (props: ILayoutProps) => {
  const data: IPageSeoProps = useStaticQuery(graphql`
    query SEOMsQuery {
      allDatoCmsSite {
        nodes {
          globalSeo {
            fallbackSeo {
              description
              title
            }
          }
        }
      }
      allDatoCmsPageSeo {
        nodes {
          seoContent {
            description
            title
          }
          slug
        }
      }
    }
  `);

  const cleanSlug = () => {
    const slug = props.pageContext && props.pageContext.originalPath || props.pathContext && props.pathContext.slug || '';
    return slug && slug.replace(/\/$/, '');
  };

  const globalSeo = data && data.allDatoCmsSite && data.allDatoCmsSite.nodes[0].globalSeo;
  const defaultTitle = globalSeo && globalSeo.fallbackSeo.title || undefined;
  const defaultDescription = globalSeo && globalSeo.fallbackSeo.description || undefined;

  const currentPageSeoContent = _filter(data && data.allDatoCmsPageSeo && data.allDatoCmsPageSeo.nodes, (o) => o.slug.replace(/\/$/, '') === cleanSlug())[0];
  const { title, description } = currentPageSeoContent && currentPageSeoContent.seoContent || { title: undefined, description: undefined };

  return {
    title: title || defaultTitle,
    description: description || defaultDescription,
  };
};

export default useSeoQuery;

